/* eslint-disable no-multiple-empty-lines */
function getErrors(error, key) {
  return error[key]
}
function getType(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
}

function errorKeys(error) {
  if (!error || getType(error) === 'string') {
    return null
  }
  return Object.keys(error)
}

const getError = error => {
  const errorMessage = 'Error de API, por favor intentarlo de nuevo.'

  if (!error.response) {
    console.error(`API ${error.config?.url} no econtrado`)
    return errorMessage
  }
  if (process.env.NODE_ENV === 'development') {
    console.error(error.response.data)
    console.error(error.response.status)
    console.error(error.response.headers)
  }
  if (error.response.data && error.response.data.errors) {
    const errorData = error.response.data.errors
    if (errorData && getType(errorData) === 'string') {
      return errorData
    }

    if (getType(errorData) === 'object') {
      let message = ''

      errorKeys(errorData).forEach(key => {
        // message += `${key.replace('_', ' ')}: `

        getErrors(errorData, key).forEach(item => {
          message += `${item} `
        })
      })

      return message
    }
  }

  return errorMessage
}

export default getError
