/**
 * Tipos de nivel de usuario administrador
 */
const ADMIN_USER_LEVELS = {
  ADMINISTRADOR: 0,
  ADMINISTRADOR_DE_PRODUCTOS: 1,
}

/**
 * Nivel de usuario administrador (0 = administrador, 1 = administrador de productos)
 *
 * @summary Obtiene la información del usuario del local storage y devuelve el nivel de usuario
 */
function adminLevel() {
  const userData = JSON.parse(localStorage.getItem('userData'))

  switch (userData.Level) {
    case 0: return ADMIN_USER_LEVELS.ADMINISTRADOR
    case 1: return ADMIN_USER_LEVELS.ADMINISTRADOR_DE_PRODUCTOS
    default: return 0
  }
}

export { adminLevel, ADMIN_USER_LEVELS }
