var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TransfersListFilters',{attrs:{"can-manage-transfers":_vm.canManageTransfers},on:{"on:filter":_vm.handleFilter}}),(_vm.isLoadingTransfers)?[_c('b-skeleton',{staticClass:"mt-1",attrs:{"animation":"wave","width":"100%","height":"430px"}})]:[_c('b-card',{staticClass:"mt-1 border border-dark shadow-none",attrs:{"no-body":""}},[_c('b-card-text',[(_vm.availableTransfers)?_c('b-table',{staticClass:"my-0",attrs:{"items":_vm.transfers,"fields":_vm.columns,"responsive":""},scopedSlots:_vm._u([{key:"cell(No)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.indexFrom + data.index)+" ")]}},{key:"cell(ID)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'transfer-details', params: { id: item.IdTransfer } }}},[_vm._v(" "+_vm._s(item.ID)+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY hh:mm a"))+" ")]}},{key:"cell(Qty)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("placeholder")(item.Qty,'Sin especificar')))])]}},{key:"cell(StatusText)",fn:function(ref){
var item = ref.item;
return [(item.StatusText)?[_c('div',{staticClass:"text-nowrap",class:[_vm.statusBorderStyle(item.StatusText), _vm.statusColorStyle(item.StatusText)],staticStyle:{"display":"inline","font-size":"11px"},attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(item.StatusText)+" ")])]:_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}}],null,false,2581671017)}):_vm._e(),(!_vm.availableTransfers && !_vm.isLoadingTransfers)?_c('b-alert',{staticClass:"my-0",attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se encontraron órdenes de traspaso.")])])]):_vm._e()],1)],1)],(_vm.availableTransfers)?_c('BasicPaginator',{ref:"basic-paginator",staticClass:"mt-2",attrs:{"callback":_vm.handleChangePagination,"total-rows":_vm.totalTransfers}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }